
// Content styles
.block-text,
.biography {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: 0 0 $paragraph-margin;
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;
			font-weight: $font-normal - 100;
			
			@media #{$mobileXL} {
				font-size: $h3 - 0.3rem;
			}
		}

		a {
			position: relative;
			padding-bottom: $site-padding - 1.25rem;
			@extend %hover-animation;
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}

	hr {
		border: none;
		margin: $block-margin 0;
		height: 2px;
		background: $border-color;
	}
}

